import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { getEditorLink, getViewerLink } from '../../../../utils/templateLinks';
import { withTranslations, WithTranslations } from '../../../../utils/withTranslations';
import { Template } from '../../../../web-api/domain/template';
import { useEditorSessionId } from '../../../contexts/EditorSessionIdContext';
import { usePreventFocusOnClick } from '../../../hooks/usePreventFocusOnClick';
import { BaseURLStore } from '../../../stores/BaseURLStore';
import { BILoggerStore } from '../../../stores/BILoggerStore';
import { ConfigStore } from '../../../stores/ConfigStore';
import { injectStoresV2 } from '../../../stores/injectStoresV2';
import { RoutingStore } from '../../../stores/RoutingStore';
import { TemplateImage } from '../../Templates/TemplateImage/TemplateImage';
import { SkeletonLine, SkeletonGroup } from '@wix/design-system';

import s from './RecommendedTemplate.scss';

export const AI_DATA_LOADING = '<LOADING>';

export interface IRecommendedTemplate extends Template {
  aiBadge: string;
  aiDescription: string;
}

interface RecommendedTemplate extends WithTranslations {
  template: IRecommendedTemplate;
  recommendedTemplateIndex: number;
  aiGeneratedContent?: { description: string; badge: string };
  biLoggerStore: BILoggerStore;
  configStore: ConfigStore;
  routingStore: RoutingStore;
  baseURLStore: BaseURLStore;
}

const RecommendedTemplateCmp: FC<RecommendedTemplate> = ({
  t,
  template,
  recommendedTemplateIndex,
  configStore,
  routingStore,
  biLoggerStore,
  baseURLStore,
}) => {
  const { editorSessionId, refreshEditorSessionId } = useEditorSessionId();
  const originUrl = baseURLStore.buildURL(routingStore.location);
  const { currentLanguage } = configStore.config;
  const { petri_ovr, dashboardFirstMetaSiteId, siteCreationFolderId } = routingStore.getGlobalUrlParams();
  const isOpenTemplateInSameTab = !!dashboardFirstMetaSiteId;

  const preventFocusOnClick = usePreventFocusOnClick();

  const editorLink = getEditorLink({
    template,
    editorSessionId,
    petri_ovr,
    dashboardFirstMetaSiteId,
    siteCreationFolderId,
  });
  const viewerLink = getViewerLink({
    templateSlug: template.id,
    language: currentLanguage,
    originUrl,
    editorSessionId,
    dashboardFirstMetaSiteId,
  });

  const handleEditButtonClick = () => {
    void biLoggerStore.logTemplateEdit({
      siteId: template.siteId,
      galleryDocIndex: recommendedTemplateIndex,
      openingBrowserUrl: editorLink,
      metaSiteId: template.metaSiteId,
      editorSessionId,
      hasVideo: !!template.videoSrc,
      origin: 'create-page' as const,
      score: template.score,
      currentPageNumber: routingStore.currentPage,
      isBestMatch: true,
    });

    setTimeout(() => refreshEditorSessionId());
  };
  const handlePreviewClick = () => {
    biLoggerStore.logTemplateView({
      siteId: template.siteId,
      galleryDocIndex: recommendedTemplateIndex,
      openingBrowserUrl: viewerLink,
      hasVideo: !!template.videoSrc,
      origin: 'thumbnail' as const,
      score: template.score,
      currentPageNumber: routingStore.currentPage,
      isBestMatch: true,
      buttonName: 'view',
    });

    setTimeout(() => refreshEditorSessionId());
  };

  useEffect(() => {
    biLoggerStore.logTemplateInViewport({
      template_id: template.siteId,
      galleryDocIndex: recommendedTemplateIndex,
      is_recommended: true,
      template_description: template.aiDescription,
      badge_description: template.aiBadge,
    });
  }, []);

  return (
    <div className={s.container}>
      <div className={s.imageBox}>
        <div className={s.hoverPanel}>
          <a
            href={editorLink}
            target={isOpenTemplateInSameTab ? '_self' : '_blank'}
            className={s.editBtn}
            data-hook="RecommendedTemplat-editBtn"
            onClick={handleEditButtonClick}
            onFocus={preventFocusOnClick}
            role="button"
          >
            {t('template.edit')}
          </a>
          <a
            href={viewerLink}
            target={isOpenTemplateInSameTab ? '_self' : '_blank'}
            className={s.previewBtn}
            data-hook="RecommendedTemplat-previewBtn"
            onClick={handlePreviewClick}
            onFocus={preventFocusOnClick}
            role="button"
            aria-haspopup="true"
          >
            {t('template.view')}
          </a>
        </div>
        <TemplateImage template={template} />
        {template.aiBadge === AI_DATA_LOADING ? (
          <div className={classNames(s.tag, s.tagLoader)}>
            <div className={s.tagLoadingPanel}></div>
          </div>
        ) : (
          <div className={classNames(s.tag, s.tagMain)}>{template.aiBadge}</div>
        )}
      </div>
      <div className={s.texts}>
        <div className={s.title}>{template.title}</div>
        {template.aiDescription === AI_DATA_LOADING ? (
          <div>
            <SkeletonGroup skin="light">
              <SkeletonLine marginBottom="5px" />
              <SkeletonLine marginBottom="5px" />
              <SkeletonLine marginBottom="5px" />
            </SkeletonGroup>
          </div>
        ) : (
          <div className={s.description}>{template.aiDescription}</div>
        )}
      </div>
    </div>
  );
};

export const RecommendedTemplate = withTranslations(
  injectStoresV2('biLoggerStore', 'configStore', 'routingStore', 'baseURLStore')(RecommendedTemplateCmp)
);
